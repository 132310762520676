<template>
  <div>
    <div class="flex">
      <h2 class="font-bold">{{ label }}</h2>
      <div class="flex-1"></div>
      <span class="text-gray-700">{{ title.count }}/4</span>
    </div>

    <EditorLine
      v-for="(item, i) in items"
      :key="i"
      :success="item.condition"
      :successMessage="item.success"
    />

    <v-divider class="mb-2" />
  </div>
</template>

<script>
import EditorLine from "@/components/editor/Line.vue";

export default {
  name: "Editor",
  components: {
    EditorLine
  },
  props: {
    title: Object,
    label: String
  },
  computed: {
    labelWithLowerCase: function() {
      return this.label.toLowerCase();
    },
    items: function() {
      const { title, labelWithLowerCase } = this;
      const items = [
        {
          condition: title.content.length > 0,
          success: `Title présent: "${title.content}"`,
          error: `Ajouter un ${labelWithLowerCase}.`
        },
        {
          condition: title.targetedKeyword,
          success: `Utiliser au moins un de vos mot(s)-clé(s) ciblé(s). N'utiliser pas plus d'une fois chaque mot(s)-clé(s) ciblé(s).`,
          error: null
        },
        {
          condition: title.length > 0 && title.length <= 120,
          success: `La longueur de votre ${labelWithLowerCase} est dans les limites de Google.`,
          error: null
        },
        {
          condition: title.rightWordCount,
          success: `Votre ${labelWithLowerCase} contient trop peu de mots. Les
          recherches montrent que les ${labelWithLowerCase}s cohérents de 5 à
          12 mots-clés attirent plus d'utilisateurs.`,
          error: null
        }
      ];
      return items;
    }
  },
  data() {
    return {};
  },
  methods: {}
};
</script>
