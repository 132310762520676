<template>
  <div id="Navigation">
    <v-navigation-drawer v-model="drawer" app clipped :width="320">
      <div class="d-flex flex-column h-full">
        <MenuPrimary />
        <EditorSettings />
        <v-divider v-if="$route.path === '/writer'" class="my-3" />
        <div class="mt-auto">
          <CompetitorDetails />
        </div>
      </div>

      <template v-slot:append>
        <UserSystemCard />
      </template>
    </v-navigation-drawer>
    <v-app-bar app clipped-left>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />

      <v-toolbar-title v-if="title" class="d-flex align-center">
        <v-img src="/img/logo.png" height="25" width="25" class="mr-2"></v-img>
        <span class="font-weight-bold text-lg">{{ title }}</span>
        <span class="ml-2 text-lg font-weight-thin">By l'Agence Web</span>
      </v-toolbar-title>
      <v-spacer />

      <Support />
      <v-btn
        v-if="loading === false && user === null"
        class="mx-2"
        text
        to="/login"
        >Se connecter</v-btn
      >
      <v-btn
        v-if="loading === false && user === null"
        class="mx-2"
        color="primary"
        to="/register"
        >Créer un compte</v-btn
      >
    </v-app-bar>
  </div>
</template>

<script>
import UserSystemCard from "@/components/user/SystemCard.vue";
import CompetitorDetails from "@/components/CompetitorDetails.vue";
import MenuPrimary from "@/components/MenuPrimary.vue";
import Support from "@/components/Support.vue";
import EditorSettings from "@/components/editor/Settings.vue";

export default {
  name: "Nav",
  props: {
    title: String
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    loading() {
      return this.$store.state.loading;
    }
  },
  components: {
    UserSystemCard,
    EditorSettings,
    Support,
    MenuPrimary,
    CompetitorDetails
  },
  methods: {},
  data: () => ({
    link: {
      logout: "/logout"
    },
    drawer: null
  })
};
</script>
