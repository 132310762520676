<template>
  <v-container class="font-dosis" fill-height>
    <Editor />
  </v-container>
</template>

<script>
import Editor from "@/components/Editor.vue";

export default {
  name: "writer",
  components: {
    Editor
  }
};
</script>
