<template>
  <v-app id="inspire">
    <NavWithoutSidebar :title="title" />

    <v-content>
      <v-container class="fill-height" fluid>
        <v-row justify="center" align="center">
          <v-col>
            <slot />
          </v-col>
        </v-row>
      </v-container>
    </v-content>

    <Snackbar />
    <!-- <Footer brand="L'agence web" /> -->
  </v-app>
</template>

<script>
import NavWithoutSidebar from "@/components/NavWithoutSidebar.vue";
import Snackbar from "@/components/Snackbar.vue";
// import Footer from "@/components/Footer.vue";

export default {
  components: {
    NavWithoutSidebar,
    Snackbar
    // Footer
  },
  computed: {
    title() {
      let title = this.$route.meta.title || null;
      return title;
    }
  },
  data: () => ({
    source: "https://google.com/",
    drawer: null,
    drawerRight: null,
    right: false,
    left: false
  })
};
</script>
