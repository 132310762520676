<template>
  <div v-if="$route.path === '/writer'">
    <div class="px-3 mb-4" v-if="isReady && competitors && competitors.length === 0">
      <span class="font-italic secondary--text text--lighten-2">Nous n'avons trouvé aucun résultat</span>
    </div>
    <div class="px-3 mb-4" v-for="(c, i) in competitors" :key="i">
      <v-card outlined class="mb-2">
        <v-card-text>
          <div class="info--text text--darken-2 text-base pb-2" title="title">
            <span v-if="c.title">{{ c.title }}</span>
            <span v-else>no title</span>
          </div>
          <div class="pb-2 text-base" title="meta description">
            <p class="text--primary text--darken-2 ma-0">
              <span v-if="c.meta && c.meta.description">{{ c.meta.description }}</span>
              <span v-else>no meta description</span>
            </p>
          </div>
          <div class="text-right text-base" title="nombre de mots">
            <span class="text--primary text--darken-2 ma-0">
              {{ c.words }} mots
            </span>
          </div>
        </v-card-text>
      </v-card>
      <v-card outlined v-if="c.questions.length">
        <div
          v-for="(q, i) in c.questions"
          :key="i"
        >
          <v-divider v-if="i > 0" />
          <div class="px-4 py-1 secondary--text text--lighten-1 text-base">{{ q }}</div>
        </div>
      </v-card>
    </div>
    <div class="px-3 mb-4" v-if="showButton">
      <v-btn
        @click="triggerCompetitorDetails"
        class="mt-2"
        :loading="isLoading"
        block
        color="primary"
      >
        voir plus de details
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "CompetitorDetails",
  components: {},
  props: {},
  computed: {
    competitors() {
      return this.$store.getters.competitor.data;
    },
    isReady() {
      return this.$store.getters.competitor.isReady;
    },
    isLoading() {
      return this.$store.getters.competitor.loading;
    },
    showButton() {
      return this.$store.getters.competitor.showButton;
    }
  },
  data() {
    return {};
  },
  methods: {
    triggerCompetitorDetails: function() {
      this.$root.$emit("trigger", "competitor");
    }
  }
};
</script>
