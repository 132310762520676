<template>
  <v-list dense rounded>
    <v-list-item v-for="item in items" :key="item.title" link :to="item.to">
      <v-list-item-icon>
        <v-icon>{{ item.icon }}</v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title class="tracking-wide">{{
          item.title
        }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          title: "Outil de rédaction",
          icon: "mdi-view-dashboard",
          to: "/writer"
        }
      ]
    };
  }
};
</script>
