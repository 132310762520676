<template>
  <div id="app">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
const default_layout = "no-sidebar";

export default {
  name: "App",
  mounted: function() {
    this.$store.commit("getUser");
  },
  computed: {
    layout() {
      let layoutName = this.$route.meta.layout || default_layout;
      return `${layoutName}-layout`;
    }
  }
};
</script>
