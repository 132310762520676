<template>
  <div id="Navigation">
    <v-app-bar app clipped-right>
      <v-toolbar-title v-if="title" class="d-flex align-center">
        <v-img src="/img/logo.png" height="25" width="25" class="mr-2"></v-img>
        <span class="font-weight-bold text-lg">{{ title }}</span>
        <span class="ml-2 text-lg font-weight-thin">By l'Agence Web</span>
      </v-toolbar-title>
      <v-spacer />

      <Support />
      <v-btn
        v-if="loading === false && user === null"
        class="mx-2"
        text
        to="/login"
        >Se connecter</v-btn
      >
      <v-btn
        v-if="loading === false && user === null"
        class="mx-2"
        color="primary"
        to="/register"
        >Créer un compte</v-btn
      >
      <v-btn v-if="loading === false && user" icon @click="logout">
        <v-icon>mdi-exit-to-app</v-icon>
      </v-btn>
    </v-app-bar>
  </div>
</template>

<script>
import Support from "@/components/Support.vue";

export default {
  name: "NavWithoutSidebar",
  props: {
    title: String
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    loading() {
      return this.$store.state.loading;
    }
  },
  components: {
    Support
  },
  methods: {
    logout: function() {
      const { $store, $route, $router, system } = this;
      const path = "/";
      $store.dispatch("logout").then(() => {
        if ($route.path !== path) $router.push(path).catch(() => {});
        this.$store.dispatch("createSnack", {
          timeout: system.timeout,
          color: "success",
          message: this.message.success
        });
      });
    }
  },
  data: () => ({
    system: {
      timeout: 2000
    },
    message: {
      success: "Vous avez été déconnecté"
    },
    link: {
      logout: "/logout"
    },
    drawer: null
  })
};
</script>
