<template>
  <v-container class="d-flex flex-column justify-center" fill-height>
    <v-btn to="/writer" color="primary">Commencer</v-btn>
  </v-container>
</template>

<script>
export default {
  name: "home",
  components: {}
};
</script>
