import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import vuetify from "@/plugins/vuetify";

// LAYOUTS
import Default from "@/layouts/Default.vue";
import NoSidebar from "@/layouts/NoSidebar.vue";

Vue.config.productionTip = false;

Vue.component("default-layout", Default);
Vue.component("no-sidebar-layout", NoSidebar);

// LAYOUTS
Parse.initialize("writer-server");
Parse.serverURL = "https://parse.writer.l-agenceweb.com/parse";

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
