<template>
  <div class="flex my-2 tracking-wide">
    <span class="mr-2" v-if="success">
      <v-icon color="success" small dense>mdi-checkbox-marked-circle</v-icon>
    </span>
    <span class="mr-2" v-else>
      <v-icon small dense>mdi-arrow-right-bold-circle</v-icon>
    </span>
    <span v-if="success" class="my-auto text-sm">{{ successMessage }}</span>
    <span v-else class="my-auto text-sm">{{
      errorMessage || successMessage
    }}</span>
  </div>
</template>

<script>
export default {
  name: "EditorLine",
  components: {},
  props: {
    success: Boolean,
    successMessage: String,
    errorMessage: String
  },
  computed: {},
  data() {
    return {};
  },
  methods: {}
};
</script>
