import Vue from "vue";
import Vuex from "vuex";

let id = 0;

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    competitor: {
      data: [],
      showButton: false,
      loading: false,
      isReady: false
    },
    snackbars: [],
    user: null,
    loading: true,
    content: {
      loading: false,
      value: ""
    },
    keyword: {
      loading: false,
      value: "",
      data: []
    },
    related: {
      loading: false,
      noRelatedKeywords: false,
      data: []
    }
  },
  getters: {
    enabledSnackbars: state => {
      return state.snackbars.filter(snack => snack.show);
    },
    content: state => {
      return state.content;
    },
    competitor: state => {
      return state.competitor;
    },
    keyword: state => {
      return state.keyword;
    },
    related: state => {
      return state.related;
    }
  },
  mutations: {
    setCompetitor(state, data) {
      state.competitor = data;
    },
    resetCompetitor(state) {
      state.competitor = {
        data: [],
        showButton: false,
        loading: false,
        isReady: false
      };
    },
    updateCompetitor(state, obj) {
      Object.assign(state.competitor, obj);
    },
    getUser(state) {
      let user = Parse.User.current();
      state.user = user;
      state.loading = false;
    },
    setUser(state, user) {
      state.user = user;
    },
    setContent(state, content) {
      if ("boolean" === typeof content.loading) {
        state.content.loading = content.loading;
      }
      if (content.value) {
        state.content.value = content.value;
      }
    },
    setKeyword(state, keyword) {
      if ("boolean" === typeof keyword.loading) {
        state.keyword.loading = keyword.loading;
      }
      if (keyword.value) {
        state.keyword.value = keyword.value;
      }
      if (keyword.data) {
        state.keyword.data = keyword.data;
      }
    },
    setRelatedKeyword(state, keyword) {
      if ("boolean" === typeof keyword.loading) {
        state.related.loading = keyword.loading;
      }
      if ("boolean" === typeof keyword.noRelatedKeywords) {
        state.related.noRelatedKeywords = keyword.noRelatedKeywords;
      }
      if (keyword.data) {
        state.related.data = keyword.data;
      }
    },
    removeFinishedSnack(state) {
      state.snackbars = state.snackbars.filter(snackbar => snackbar.show);
    },
    createSnack(state, { message, timeout, color }) {
      state.snackbars.push({ id: id, message, timeout, color, show: true });
      id++;
    }
  },
  actions: {
    logout({ commit }) {
      Parse.User.logOut()
        .catch(() => {})
        .then(() => commit("setUser", null));
    },
    createSnack({ commit }, data) {
      commit("removeFinishedSnack");
      commit("createSnack", data);
    },
    setCompetitorWithKeywords({ commit, state }, keywords) {
      state.competitor.loading = true;
      const json = state.competitor;
      json.data = [];
      json.isReady = false;
      const filteredArray = keywords.filter(item => {
        return item.competitor;
      });
      let counter = 0;
      filteredArray.forEach(async (item) => {
        counter++;
        const res = await Parse.Cloud.run("fetchDetails", {
          hide_content: true,
          url: item.competitor.href
        });
        if (res.status === 200) {
          const cpy = Object.assign({}, res);
          cpy.questions = item.competitor.questions;
          json.data.push(cpy);
        }
        if (filteredArray.length === counter) {
          state.competitor.isReady = true;
          state.competitor.loading = false;
          state.competitor.showButton = false;
          commit("setCompetitor", json);
        }
      });
    },
    showCompetitorButton({ commit, state }) {
      const json = state.competitor;
      json.showButton = true;
      commit("setCompetitor", json);
    }
  },
  modules: {}
});
