<template>
  <div class="px-4 mt-4" v-if="$route.path === '/writer'">
    <v-btn
      @click="triggerContent"
      class="mt-2"
      depressed
      small
      text
      color="primary"
    >
      importer une page
    </v-btn>
    <v-btn
      @click="triggerKeyword"
      class="mt-2"
      depressed
      small
      text
      color="primary"
    >
      importer des mot(s)-clé(s)
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "EditorSettings",
  components: {},
  props: {},
  computed: {
    content() {
      return this.$store.getters.content;
    },
    keyword() {
      return this.$store.getters.keyword;
    }
  },
  data() {
    return {};
  },
  methods: {
    triggerContent: function() {
      this.$store.commit("setContent", { loading: true });
      this.$root.$emit("trigger", "content");
    },
    triggerKeyword: function() {
      this.$store.commit("setKeyword", { loading: true });
      this.$root.$emit("trigger", "keyword");
    }
  }
};
</script>
