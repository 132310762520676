<template>
  <v-card v-if="user()" dense :raised="true" :elevation="5" class="transparent">
    <v-list-item dense>
      <v-list-item-avatar color="grey darken-3">
        <v-img
          class="elevation-6"
          src="https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light"
        ></v-img>
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title>{{ user().get("email") }}</v-list-item-title>
      </v-list-item-content>

      <v-list-item-action>
        <v-menu top left nudge-top="60" min-width="231">
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon class="mr-1">mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item-group color="primary">
              <v-list-item v-if="isEditor" to="/users" color="primary">
                <v-list-item-icon>
                  <v-icon>mdi-account-details</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Utilisateurs</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider v-if="isEditor" />
              <v-list-item to="/settings" color="primary">
                <v-list-item-icon>
                  <v-icon>mdi-settings-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Settings</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider />
              <v-list-item @click="logout">
                <v-list-item-icon>
                  <v-icon>mdi-exit-to-app</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Se déconnecter</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </v-list-item-action>
    </v-list-item>
  </v-card>
</template>

<script>
export default {
  name: "SystemCard",
  props: {},
  components: {},
  created() {
    this.start();
  },
  methods: {
    logout: function() {
      const { $store, $router, system } = this;
      $store.dispatch("logout").then(() => {
        $router.replace("/");
        this.$store.dispatch("createSnack", {
          timeout: system.timeout,
          color: "success",
          message: this.message.success
        });
      });
    },
    user: function() {
      return Parse.User.current();
    },
    start: async function() {
      const res = await Parse.Cloud.run("hasRole", { roleName: "editor" });
      this.isEditor = res.hasRole;
    }
  },
  data: () => ({
    system: {
      timeout: 2000
    },
    isEditor: false,
    message: {
      success: "Vous avez été déconnecté"
    }
  })
};
</script>
