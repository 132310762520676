<template>
  <v-alert prominent class="custom-grad" icon="mdi-fire" dark>
    <v-row v-if="user" align="center">
      <v-col class="grow">Supprimer les limites de votre compte</v-col>
      <v-col class="shrink">
        <v-btn outlined small to="/billing">voir</v-btn>
      </v-col>
    </v-row>
    <v-row v-else align="center">
      <v-col class="grow">Connectez-vous pour continuer</v-col>
      <v-col class="shrink">
        <v-btn outlined small to="/login">Commencer</v-btn>
      </v-col>
    </v-row>
  </v-alert>
</template>

<script>
export default {
  name: "EditorAction",
  props: {},
  data() {
    return {};
  },
  methods: {
    user() {
      return this.$store.state.user;
    }
  }
};
</script>
