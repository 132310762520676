<template>
  <v-progress-circular
    class="my-2"
    :rotate="360"
    :size="100"
    :width="10"
    :value="value"
    color="primary"
  >
    <span class="font-weight-bold text-xl">{{ value }} / 100</span>
  </v-progress-circular>
</template>

<script>
export default {
  props: {
    completedSteps: Number,
    totalSteps: Number
  },
  data() {
    return {};
  },
  computed: {
    value() {
      const { completedSteps, totalSteps } = this;
      const value = (completedSteps * 100) / totalSteps;
      return Math.round(value);
    }
  }
};
</script>
