<template>
  <div>
    <v-dialog v-model="dialog" max-width="310">
      <template v-slot:activator="{ on }">
        <v-btn icon v-on="on">
          <v-icon>mdi-lifebuoy</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="headline my-2">Support</v-card-title>
        <v-card-text class="mt-4">
          <v-textarea
            outlined
            v-model="form.msg"
            @change="change"
            :rows="10"
            :no-resize="true"
            :hide-details="true"
            color="blue darken-4"
            label="message"
          ></v-textarea>
        </v-card-text>
        <v-card-actions class="px-6 pt-4 pb-2">
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="dialog = false">Retour</v-btn>
          <v-btn
            color="primary"
            @click="sendMessageToSupport"
            :loading="form.loading"
            :disabled="!form.validate"
          >
            Envoyer
            <v-icon right dark>mdi-send</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "Support",
  props: {},
  watch: {
    "form.msg"() {
      this.change();
    }
  },
  components: {},
  methods: {
    sendMessageToSupport: async function() {
      const { system } = this;
      this.form.loading = true;
      if (!this.form.msg || this.form.msg.length === 0) {
        this.form.loading = false;
        return;
      }
      const res = await Parse.Cloud.run("sendMessageToSupport", {
        msg: this.form.msg
      });
      if (res && res.result) {
        this.form.msg = "";
        this.dialog = false;
        this.$store.dispatch("createSnack", {
          timeout: system.timeout,
          color: "success",
          message: this.message.success
        });
      } else {
        this.$store.dispatch("createSnack", {
          timeout: system.timeout,
          color: "error",
          message: this.message.error
        });
      }
      this.form.loading = false;
    },
    change: function() {
      const { form } = this;
      if (form.msg && form.msg.length > 0) {
        form.validate = true;
      } else {
        form.validate = false;
      }
    }
  },
  data: () => ({
    dialog: false,
    system: {
      timeout: 2000
    },
    message: {
      error: "Erreur, essayer plus tard.",
      success: "Message envoyé"
    },
    form: {
      msg: "",
      loading: false,
      validate: false
    }
  })
};
</script>
