<template>
  <v-app id="inspire">
    <Nav :title="title" />

    <v-content>
      <slot />
    </v-content>

    <Snackbar />
  </v-app>
</template>

<script>
import Nav from "@/components/Nav.vue";
import Snackbar from "@/components/Snackbar.vue";

export default {
  components: {
    Nav,
    Snackbar
  },
  computed: {
    title() {
      let title = this.$route.meta.title || null;
      return title;
    }
  },
  data: () => ({
    source: "https://google.com/",
    drawer: null,
    drawerRight: null,
    right: false,
    left: false
  })
};
</script>
