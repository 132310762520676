import Vue from "vue";
import VueRouter from "vue-router";
import Writer from "../views/Writer.vue";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    meta: {
      title: "Writer"
    },
    component: Home
  },
  {
    path: "/writer",
    name: "writer",
    meta: {
      title: "Writer",
      layout: "default"
    },
    component: Writer
  },
  {
    path: "/about",
    name: "about",
    meta: {
      title: "Writer"
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  },
  {
    path: "/users",
    name: "users",
    meta: {
      requiresAuth: true,
      title: "Writer",
      layout: "default"
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Users.vue")
  },
  {
    path: "/settings",
    name: "settings",
    meta: {
      requiresAuth: true,
      title: "Writer",
      layout: "default"
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Settings.vue")
  },
  {
    path: "/choose_password",
    name: "choosePassword",
    meta: {
      title: "Writer"
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ChoosePassword.vue")
  },
  {
    path: "/password_reset_success",
    name: "passwordResetSuccess",
    meta: {
      title: "Writer"
    },
    component: () => import("../views/PasswordResetSuccess.vue")
  },
  {
    path: "/verify_email_success",
    name: "verifyEmailSuccess",
    meta: {
      title: "Writer"
    },
    component: () => import("../views/VerifyEmailSuccess.vue")
  },
  {
    path: "/login",
    name: "login",
    meta: {
      guest: true,
      title: "Writer"
    },
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue")
  },
  {
    path: "/register",
    name: "register",
    meta: {
      guest: true,
      title: "Writer"
    },
    component: () =>
      import(/* webpackChunkName: "register" */ "../views/Register.vue")
  },
  {
    path: "/recover",
    name: "recover",
    meta: {
      guest: true,
      title: "Writer"
    },
    component: () =>
      import(/* webpackChunkName: "recover" */ "../views/Recover.vue")
  },
  {
    path: "*",
    meta: {
      title: "Writer"
    },
    component: () =>
      import(/* webpackChunkName: "recover" */ "../views/NotFound.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(async (to, from, next) => {
  let user = await Parse.User.current();
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (user == null) {
      return next({
        path: "/login"
      });
    }
    next();
  } else if (to.matched.some(record => record.meta.guest)) {
    if (user == null) {
      next();
    } else {
      next({ name: "writer" });
    }
  } else {
    next();
  }
});

export default router;
