<template>
  <div>
    <v-snackbar
      v-for="snack in enabledSnackbars"
      :color="snack.color"
      :bottom="true"
      :key="snack.id"
      v-model="snack.show"
      :timeout="snack.timeout"
    >
      {{ snack.message }}
      <v-btn dark text @click="snack.show = false">
        Fermer
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: "Snackbar",
  props: {},
  computed: {
    enabledSnackbars() {
      return this.$store.getters.enabledSnackbars;
    }
  },
  components: {},
  methods: {},
  data: () => ({})
};
</script>
