<template>
  <v-flex column fill-height>

    <v-dialog v-model="dialog" max-width="400">
      <v-card>
        <v-card-actions>
          <v-spacer />
          <v-btn color="secondary darken-1" text icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
        <v-card-title class="headline">Importer une page</v-card-title>
        <v-card-text class="">
          <form @submit.prevent="submitLinkAsQuery">
            <v-text-field
              label="Url"
              placeholder="https://www.example.com/about"
              id="domain"
              filled
              v-model="form.domain"
              dense
              hide-details
            ></v-text-field>
            <v-btn
              type="submit"
              class="mt-2"
              depressed
              small
              dark
              color="primary"
              :loading="loading.html"
            >
              importer
            </v-btn>
          </form>
        </v-card-text>
        <v-card-title class="headline">Ajouter des mot(s)-clé(s)</v-card-title>
        <v-card-text class="">
          <form @submit.prevent="submitKws" class="mt-4">
            <v-text-field
              label="Mot-clé(s)"
              placeholder="Agence de référencement naturel, vélo, auto"
              id="keywords"
              filled
              v-model="form.keywords"
              dense
              hide-details
            ></v-text-field>
            <v-btn
              type="submit"
              class="mt-2"
              depressed
              small
              dark
              color="primary"
              :loading="loading.related"
            >
              ajouter
            </v-btn>
          </form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer />
          <v-btn color="blue darken-1" text @click="dialog = false"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-col
      cols="12"
      sm="12"
      lg="11"
      xl="9"
      class="mx-auto py-0 fill-height d-flex flex-column">

      <!-- CONTENT BEFORE / START -->

      <EditorAlert v-if="quota.reached" class="w-full" />

      <v-card class="w-full mb-4">
        <v-card-text>
          <div block text class="d-flex text-left">
            <v-icon
              class="mr-2"
              :color="
                card.autofocus[0] === false ? 'transparent' : 'secondary'
              "
              small
              >mdi-chevron-right</v-icon
            >
            <div class="info--text text--darken-2 text-xl cursor-pointer"  @click="trigger(0)">
              {{ form.title || "no title" }}
            </div>
          </div>
        </v-card-text>
        <div class="px-4 pb-4">
          <div class="d-flex align-center">
            <v-icon
              class="mr-2"
              :color="
                card.autofocus[1] === false ? 'transparent' : 'secondary'
              "
              small
              >mdi-chevron-right</v-icon
            >
            <p class="text--primary text--darken-2 ma-0 cursor-pointer" @click="trigger(1)">
              {{ form.description || "no meta description" }}
            </p>
          </div>
        </div>
      </v-card>

      <div v-if="card.edit" class="w-full">
        <div class="d-flex mt-4">
          <v-btn
            class="ml-auto"
            small
            text
            icon
            color="primary"
            title="Cacher"
            @click="hideCardDetails"
          >
            <v-icon>mdi-minus-box-outline</v-icon>
          </v-btn>
        </div>
        <v-text-field
          v-model="form.title"
          color="primary"
          label="Title"
          placeholder="Start typing..."
          loading
          ref="autofocusField00"
          @click="card.autofocus = [true, false]"
        >
          <template v-slot:progress>
            <v-progress-linear
              :value="progressTitle"
              :color="colorTitle"
              absolute
              height="7"
            ></v-progress-linear>
          </template>
        </v-text-field>

        <v-text-field
          class="mt-2"
          v-model="form.description"
          color="primary"
          label="Meta description"
          placeholder="Start typing..."
          loading
          ref="autofocusField01"
          @click="card.autofocus = [false, true]"
        >
          <template v-slot:progress>
            <v-progress-linear
              :value="progressMetaDescription"
              :color="colorMetaDescription"
              absolute
              height="7"
            ></v-progress-linear>
          </template>
        </v-text-field>
      </div>
      <!-- CONTENT BEFORE / END -->

      <v-layout d-flex row fill-height>

        <v-col cols="12" sm="12" md="8" lg="8" style="min-height: 70%;">
          <!-- COLUMN LEFT -->
          <textarea
            id="editor1"
            class="fill-height w-full"
            name="editor1"
          ></textarea>
        </v-col>
        <v-col cols="12" sm="12" md="4" lg="4">
          <!-- COLUMN RIGHT -->
          <v-layout id="editorcontainer" d-flex column fill-height style="overflow: hidden;">
            <v-card v-bind:class="{ 'aside': showAside }">
              <v-col cols="12">

                <div class="d-flex align-center">
                  <Score
                    class="mt-2 mx-auto"
                    :completedSteps="score.value"
                    :totalSteps="score.max"
                  />
                </div>

                <v-divider class="mb-2" />

                <span class="font-weight-bold text-xl">Mots</span>

                <p class="mt-2 secondary--text">{{ word.wordCount }}</p>

                <div class="d-flex">
                  <div class="text-right text-sm" style="width:75%;">
                    <span class="mx-2 py-6">
                      Cible <span class="font-bold">1000</span>
                    </span>
                  </div>
                  <v-divider
                    vertical
                    class="mt-auto"
                    style="height: 7px;margin-left: -1px;"
                  ></v-divider>
                </div>

                <v-progress-linear
                  class="mb-2"
                  :color="word.color"
                  :value="word.width"
                ></v-progress-linear>

                <v-divider class="mb-2" />

                <!-- TARGETED KEYWORDS -->
                <span v-if="keywords.length" class="font-weight-bold text-xl"
                  >Mots-clé(s) ciblé(s)</span
                >

                <v-row v-if="keywords.length">
                  <p class="inline-flex flex-wrap pa-2 mb-0 text-gray-700">
                    <v-chip
                      v-for="row in keywords"
                      small
                      :color="row.isInText ? 'primary' : 'warning'"
                      :key="row.keyword"
                      class="my-1 mx-1"
                    >
                      <v-icon small v-if="!row.isInText" left>
                        mdi-alert-outline
                      </v-icon>
                      {{ row.keyword }}
                    </v-chip>
                  </p>
                </v-row>

                <v-divider v-if="keywords.length" class="mb-2" />

                <span v-if="related.length" class="font-weight-bold text-xl"
                  >Mots-clé(s) recommandé(s)</span
                >

                <v-row v-if="related.length">
                  <p class="text-xs mb-2 pa-3">
                    Vous pouvez enrichir votre texte avec ces ces mot-clé(s)
                    pour avoir de meilleurs résultats SEO
                  </p>
                  <p class="inline-flex flex-wrap pa-2 mb-0 text-gray-700">
                    <v-chip
                      v-for="(row, index) in related"
                      small
                      :color="row.isInText ? 'primary' : 'default'"
                      :key="row.keyword"
                      class="my-1 mx-1"
                    >
                      {{ row.keyword }}
                      <v-icon small right v-on:click="hideKeyword(index)">
                        mdi-close-circle
                      </v-icon>
                    </v-chip>
                  </p>
                </v-row>

                <v-divider v-if="related.length" class="mb-2" />

                <!-- TITLE -->
                <BlockTitle :title="title" label="Title" />

                <!-- H1 -->
                <BlockTitle :title="h1" label="H1" />

                <!-- PARAGRAPH -->
                <span class="font-weight-bold text-xl">Paragraphe(s)</span>

                <EditorLine
                  :success="p.easyRead"
                  successMessage="Les paragraphes semblent faciles à lire."
                />

                <EditorLine
                  :success="p.targetedKeywordInAtTheFirstP"
                  successMessage="Utiliser au moins un de vos mot(s)-clé(s) ciblé(s) dans le
                premier paragraphe. Un sujet clair attire plus
                d'utilisateurs."
                />

                <v-divider class="mb-2" />

                <!-- LINKING -->
                <!-- <span v-if="related.length" class="font-weight-bold text-xl">Lien(s)</span>
                <EditorLine :success="!links.needMore" successMessage="Ajouter des liens vers votre contenu pour le rendre plus fiable
                et utile à vos lecteurs." />
                <v-divider class="mb-2" /> -->

              </v-col>
            </v-card>
          </v-layout>
        </v-col>

      </v-layout>

    </v-col>

  </v-flex>

</template>

<script>
import Score from "@/components/Score.vue";
import BlockTitle from "@/components/editor/BlockTitle.vue";
import EditorLine from "@/components/editor/Line.vue";
import EditorAlert from "@/components/editor/Alert.vue";

function update(vm) {
  let encodedContent = vm.getData();
  let content = htmlDecode(encodedContent);
  let score = 0;
  let easyRead = false;
  if (content.length > 0) {
    easyRead = true;
  }
  vm.word.wordCount = countWords(content);
  if (vm.word.wordCount < 250) {
    vm.word.color = "red";
  } else if (vm.word.wordCount < 500) {
    vm.word.color = "orange";
  } else if (vm.word.wordCount < 750) {
    vm.word.color = "orange";
    score++;
  } else if (vm.word.wordCount < 1200) {
    vm.word.color = "green";
    score++;
  } else {
    vm.word.color = "green";
    score++;
  }
  let width = (vm.word.wordCount * 75) / 1000;
  vm.word.width = width <= 100 ? width : 100;
  vm.readingTime = readingTime(vm.word.wordCount);
  vm.count(content);

  let internal = 0;
  let a = $(".cke_wysiwyg_frame")
    .contents()
    .find("a");
  a.each((i, el) => {
    let conditions =
      el.pathname.startsWith("/") ||
      (vm.hostname && el.pathname.search(vm.hostname) !== -1);
    if (conditions) {
      internal++;
    }
  });
  vm.links.needMore = internal > vm.word.wordCount / 15 ? false : true;
  if (vm.links.needMore === false) {
    // score++;
  }
  vm.links.count = a.length;
  vm.links.internal = internal;

  let title = $(".cke_wysiwyg_frame")
    .contents()
    .find("h1");
  $(".cke_wysiwyg_frame")
    .contents()
    .find("p")
    .each(function(index) {
      const innerText = this.innerText;
      if (index === 0) {
        let targetedKeywordInAtTheFirstP = false;
        vm.keywords.forEach(el => {
          const count = occurrences(innerText, el.keyword);
          if (count > 0) {
            score++;
            targetedKeywordInAtTheFirstP = true;
          }
        });
        vm.p.targetedKeywordInAtTheFirstP = targetedKeywordInAtTheFirstP;
      }
      let length = countWords(innerText);
      if (length > 170 && content.length) {
        return (easyRead = false);
      }
    });
  if (easyRead) {
    score++;
  }
  vm.p.easyRead = easyRead;
  const h1 = title.text();

  vm.h1 = vm.findTargetedKeywords(h1);
  vm.title = vm.findTargetedKeywords(vm.form.title);
  vm.score.value = score + vm.title.count + vm.h1.count;
  let nMissingAlt = 0;
  $(".cke_wysiwyg_frame")
    .contents()
    .find("img")
    .each(function() {
      let alt = $(this).attr("alt");
      if (!alt || alt.length === 0) {
        nMissingAlt++;
        return;
      }
    });
  vm.img.nMissingAlt = nMissingAlt;
}

export default {
  name: "Editor",
  components: {
    Score,
    BlockTitle,
    EditorLine,
    EditorAlert
  },
  created: function() {
    this.init();
    const { link } = this.$route.query;
    if (link && link.length) {
      this.form.domain = link;
    }
    this.submitPage();
  },
  mounted: function() {
    const self = this;
    const editorElem = document.getElementById("editorcontainer");
    CKEDITOR.replace("editor1", {
      language: "fr",
      on: {
        instanceReady: function(evt) {
          evt.editor.resize("100%", editorElem.clientHeight);
        }
      }
    });
    $(window).resize(function() {
      const editorElem = document.getElementById("editorcontainer");
      CKEDITOR.instances.editor1.resize("100%", editorElem.clientHeight);
    });
    CKEDITOR.on("instanceReady", function() {
      self.setData();
    });
    CKEDITOR.instances.editor1.on("change", function() {
      self.run();
    });
    this.$root.$off("trigger");
    this.$root.$on("trigger", res => {
      if (res === "content") {
        this.dialog = true;
      }
      if (res === "keyword") {
        this.dialog = true;
      }
      if (res === "competitor") {
        this.$store.dispatch("setCompetitorWithKeywords", this.keywords);
      }
    });
  },
  beforeDestroy: function() {
    $(window).off("resize");
  },
  watch: {
    "form.title": function() {
      this.run();
    }
  },
  data() {
    return {
      dialog: false,
      card: {
        edit: false,
        autofocus: [false, false]
      },
      system: {
        timeout: 2000
      },
      quota: {
        reached: false
      },
      limited: false,
      form: {
        domain: "",
        keywords: "",
        title: "",
        description: ""
      },
      dictionnary: [],
      debug: false,
      loading: {
        html: false,
        related: false
      },
      hostname: null,
      content: null,
      noRelatedKeywords: false,
      questions: [],
      related: [],
      keywords: [],
      p: {
        easyRead: false
      },
      links: {
        needMore: true,
        count: 0,
        internal: 0
      },
      word: {
        wordCount: 0,
        width: 0,
        color: "red"
      },
      title: {
        content: "",
        wordCount: 0,
        length: 0,
        targetedKeyword: false,
        toManyTargetedKeyword: false,
        count: 0,
        total: 4
      },
      h1: {
        content: "",
        wordCount: 0,
        length: 0,
        targetedKeyword: false,
        toManyTargetedKeyword: false,
        count: 0,
        total: 4
      },
      img: {
        nMissingAlt: 0
      },
      score: {
        value: 0,
        max: 11
      }
    };
  },
  computed: {
    showAside() {
      const { $vuetify } = this;
      const { breakpoint } = $vuetify;
      return !breakpoint.smAndDown;
    },
    progressTitle() {
      let value = (this.form.title.length * 100) / 58;
      return Math.min(100, value);
    },
    colorTitle() {
      if (this.form.title.length < 20) {
        return "error";
      }
      if (this.form.title.length > 70) {
        return "error";
      }
      if (this.progressTitle === 100) {
        return "success";
      }
      return "warning";
    },
    progressMetaDescription() {
      let value = (this.form.description.length * 100) / 160;
      return Math.min(100, value);
    },
    colorMetaDescription() {
      if (this.form.description.length < 20) {
        return "error";
      }
      if (this.form.description.length > 300) {
        return "error";
      }
      if (this.progressMetaDescription === 100) {
        return "success";
      }
      return "warning";
    }
  },
  methods: {
    getData: function() {
      let value = CKEDITOR.instances["editor1"].getData();
      return value;
    },
    setData: function() {
      if (this.content === null) {
        return;
      }
      CKEDITOR.instances["editor1"].setData(this.content);
    },
    trigger: function(index) {
      this.card.edit = true;
      this.card.autofocus = [false, false];
      this.card.autofocus[index] = true;
      setTimeout(() => {
        this.$refs[`autofocusField0${index}`].focus();
      }, 0);
    },
    hideCardDetails: function() {
      this.card.edit = false;
      this.card.autofocus = [false, false];
    },
    run: function() {
      _.debounce(() => update(this), 500)();
    },
    findTargetedKeywords: function(textChunk) {
      const response = {
        wordCount: 0,
        targetedKeyword: false,
        toManyTargetedKeyword: false,
        content: textChunk.trim() || "",
        count: 0,
        total: 4
      };
      let { content } = response;
      if (content.length > 0 && content.length <= 120) {
        response.count++;
      }
      response.length = content.length;
      if (content && content.length) {
        response.count++;
        response.wordCount = countWords(content);
        response.rightWordCount =
          response.wordCount >= 5 && response.wordCount <= 12;
        if (response.rightWordCount) response.count++;

        const { keywords } = this;
        keywords.forEach(el => {
          const count = occurrences(content, el.keyword);
          if (count > 1) {
            response.toManyTargetedKeyword = true;
          } else if (count === 1) {
            response.targetedKeyword = true;
          }
        });
        if (
          response.targetedKeyword &&
          response.toManyTargetedKeyword === false
        )
          response.count++;
      }
      return response;
    },
    init: function() {
      this.related = [];
      this.keywords = [];
      this.questions = [];
      this.dictionnary = [];
      this.noRelatedKeywords = false;
      this.limited = false;
      this.$store.commit("resetCompetitor");

      const keywordObject = { loading: true, value: "", noRelatedKeywords: false, data: [] };
      this.$store.commit("setKeyword", keywordObject);
      this.$store.commit("setRelatedKeyword", keywordObject);
    },
    hideKeyword: function(index) {
      let { keyword } = this.related[index];
      let res = confirm(`Voulez-vous cacher le mot-clé "${keyword}" ?`);
      if (res) {
        this.related.splice(index, 1);
      }
    },
    load: function(value = {}) {
      if (isObject(value) === false) {
        return;
      }
      let html = value.html === false ? value.html : true;
      let related = value.related === false ? value.related : true;
      this.loading.html = html;
      this.loading.related = related;
    },
    push: function(data, field, stack) {
      let val = field ? data[field] : data;
      if (this.dictionnary.indexOf(val) < 0) {
        this.dictionnary.push(val);
        stack.push(data);
        return true;
      }
      return false;
    },
    count: function(text) {
      const self = this;
      const { keywords, related } = self;
      keywords.forEach((el, i) => {
        const count = occurrences(text, el.keyword);
        self.keywords[i].count = count;
        self.keywords[i].isInText = count > 0 ? true : false;
      });
      related.forEach((el, i) => {
        const count = occurrences(text, el.keyword);
        self.related[i].count = count;
        self.related[i].isInText = count > 0 ? true : false;
      });
    },
    fetchContent: async function(domain) {
      const self = this;
      const res = await Parse.Cloud.run("fetchHtml", { url: domain });
      self.load({ html: false, related: self.loading.related });

      if (!res || !res.content) {
        const message = "Page introuvable";
        this.$store.dispatch("createSnack", {
          timeout: self.system.timeout,
          color: "warning",
          message
        });
        return;
      }
      const { content, hostname, title, meta } = res;
      self.content = content;
      self.form.title = title || "";
      self.form.description = meta.description || "";
      self.hostname = hostname;
      CKEDITOR.instances["editor1"].setData(content);
      self.run();
    },
    formatKeywords: function(arr, opts = {}) {
      const isInText = opts.isInText ? true : false;
      const field = opts.field ? opts.field : "Keyword";
      return arr
        .filter(el => {
          if (opts.isObject) {
            return true;
          }
          return el && el.length !== 0 && el.trim().length !== 0;
        })
        .map(el => {
          const copiedObject = Object.assign({}, opts.isObject ? el : {});
          if (opts.isObject !== true) {
            const keyword = el;
            return { keyword: keyword.toLowerCase(), isInText, count: 0 };
          }
          const keyword = el[field];
          copiedObject.keyword = keyword.toLowerCase();
          copiedObject.isInText = isInText;
          copiedObject.count = 0;
          return copiedObject;
        });
    },
    fetchSuggests: async function({ keyword, key }) {
      const self = this;
      const res = await Parse.Cloud.run("fetchSuggestsV2", { keyword, key });
      if (!res || !res.data || !Array.isArray(res.data)) {
        return null;
      }
      const arr = res.data;
      const tmp = self.formatKeywords(arr, { isObject: false });
      tmp.forEach(el => self.push(el, "keyword", self.related));
      if (tmp.length) {
        this.$store.dispatch("showCompetitorButton");
      }
      const cpy = Object.assign({}, res.competitor);
      cpy.questions = res.questions;
      return cpy;
    },
    fetchKeywordsRelated: async function({ keyword, key }) {
      const self = this;
      const res = await Parse.Cloud.run("fetchRelatedV2", { keyword, key });
      if (!res || !res.data || !Array.isArray(res.data)) {
        return;
      }
      const arr = res.data;
      const tmp = self.formatKeywords(arr, { isObject: true });
      // const arr = ["Agence de référencement"];
      // const tmp = self.formatKeywords(arr);
      tmp.forEach(el => self.push(el, "keyword", self.related));
      return tmp;
    },
    submitLinkAsQuery: function() {
      const { domain } = this.form;
      this.$router.push({ query: { link: domain }});
      this.submitPage(domain);
    },
    submitPage: function(overwroteLink) {
      const link = overwroteLink || this.$route.query.link;
      if (link && link.length) {
        this.load({ html: true, related: this.loading.related });
        this.fetchContent(link);
      }
    },
    submitKws: async function() {
      const self = this;
      const { $store } = this;
      const { keywords } = self.form;

      this.init();
      this.load({ html: self.loading.html, related: true });

      const tmp = CSVtoArray(keywords);
      if (tmp) {
        const data = this.formatKeywords(tmp);
        const details = { loading: false, value: keywords, data: data };
        this.keywords = data;
        $store.commit("setKeyword", details);
      }
      if (self.keywords.length === 0) {
        self.load({ html: self.loading.html, related: false });
        const message = "Erreur, aucun mot-clé(s) n'a été ajouté.";
        $store.dispatch("createSnack", {
          timeout: self.system.timeout,
          color: "warning",
          message
        });
        return;
      }

      const res = await Parse.Cloud.run("create");
      if (res && res.error) {
        self.load({ html: self.loading.html, related: false });
        self.quota.reached = true;
        return;
      }
      if (!res || !res.id) {
        return;
      }
      if (res.limited === true) {
        self.limited = true;
      }

      let counter = 0;
      self.keywords.forEach(async (el, i) => {
        const params = { keyword: el.keyword, key: res.id };
        await self.fetchKeywordsRelated(params);
        const json = await self.fetchSuggests(params);
        if (json) {
          self.keywords[i].competitor = json;
        }
        counter++;
        if (counter === self.keywords.length) {
          self.run();
          self.load({ html: self.loading.html, related: false });

          let details = { loading: false, noRelatedKeywords: false, data: self.related };
          $store.commit("setRelatedKeyword", details);

          if (self.related.length === 0) {
            self.noRelatedKeywords = true;
            details.noRelatedKeywords = true;
            $store.commit("setRelatedKeyword", details);
          }
        }
      });
    }
  }
};
</script>
